(function (window, document, $) {
	"use strict";

	var Modal = function (element) {
		this.$element = $(element);
		this.target = this.$element.data('target');
		this.method = this.$element.data('method') || 'GET';
		this.query = this.$element.data('query') || {};
		this.width_class = this.$element.data('width') || 'gr-10';
		this.btn_class = this.$element.data('btn-mv') || 'suffix-1';
		this.$element.on('click.modal', this.open.bind(this));

		return this;
	};
	Modal.prototype = {
		close: function (event) {
			event.preventDefault();
			var $modal = $(event.currentTarget).parents('.modal');
			$('body').removeClass('modal-open');
			$modal.fadeIn({
				duration: 100, //chng
				complete: function () {
					$modal.remove();
				}
			});
		},
		open: function (event) {
			event.preventDefault();

			var $element = this.$element;

			$element.trigger('modal.preopen', [this]);

			var lb_promise = this.showLightbox(),
				ajax_promise = $.ajax({
					url: this.target,
					method: this.method,
					data: this.query
				});

			var ancho = this.width_class;
			var btn_mv = this.btn_class;

			$.when(lb_promise, ajax_promise).then(function (lb, ajax_res) {
				var $modal = lb,
					response = ajax_res[0];

				$modal.html(response);
				$modal.addClass('loaded').addClass(ancho);
				$('[data-role="modal-btn"].modal__button').addClass(btn_mv);

				$element.trigger('modal.complete', [$modal, response]);
			});
			$('body').addClass('modal-open').addClass('automodal');
		},

		showLightbox: function () {
			var promise = new $.Deferred(),
				$modal = $('<div class="modal" id="modal-body"><div class="modal__body" data-role="modal-body"><button data-role="modal-btn" class="modal__button"></button><div data-role="modal-content" class="modal__content"></div></div></div>');

			$modal.find('[data-role="modal-btn"]').on('click.modal', this.close.bind(this));

			$('body').append($modal);

			$modal.addClass('modal--loaded');

			// RESOLVE SEGUN DISPONIBILIDAD
			var ended = false;
			$modal.on('transitionend', function () {
				ended = true;
				promise.resolve($modal.find('[data-role="modal-content"]'));
			});

			setTimeout(function () {
				if (!ended) {
					ended = true;
					promise.resolve($modal.find('[data-role="modal-content"]'));
				}
			}, 100);

			setTimeout(function () {
				$('[data-role="modal-btn"]').addClass('loaded');
			}, 500);

			return promise;
		}
	};


	$.fn.modal = function () {
		if (this.data('modal')) { return this.data('modal'); }
		return this.each(function (i, el) {
			$(el).data('modal', (new Modal(el)));
		});
	};

	// self initialization
	$(document).ready(function () {
		$('[data-module="automodal"]').modal();
	});
}(window, document, jQuery));

$(document).ready(function () {
	//función a ejecutar una vez todo script se encuentre cargado
	//delay de 50ms
	var everythingLoaded = setInterval(function () {
		if (/loaded|complete/.test(document.readyState)) {
			clearInterval(everythingLoaded);
			jQuery.each(jQuery('#hidden-modal'), function () {
				$(this).trigger('click');
			});
		}
	}, 200);
});